import store from "@/store/store";
export const token = store.state.auth.token;

export const api_url = process.env.VUE_APP_API_URL;
export const base_url = process.env.VUE_APP_BASE_URL + api_url;

export const header = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  },
};

export const user_urls = {
  user_token: "/get-user-token/",
  reset_password: "/reset_password/",
  edit_password: "/reset_password/confirm/",
  change_password: "/users/change_password/",
  get_details: (userId) => `/users/${userId}/`,
};

export const bu_urls = {
  get_all: "/businessunits/search/",
  get_details: (businessUnitId) => `/businessunits/${businessUnitId}/`,
};

export const themes_urls = {
  get_all: "/themes/search/",
  get_details: (themeId) => `/themes/${themeId}/`,
  update: (themeId) => `/themes/${themeId}/`,
  archive: (themeId) => `/themes/${themeId}/archive/`,
};

export const subthemes_urls = {
  get_all: "/subthemes/search/",
  get_details: (subthemeId) => `/subthemes/${subthemeId}/`,
  update: (subthemeId) => `/subthemes/${subthemeId}/`,
  archive: (subthemeId) => `/subthemes/${subthemeId}/archive/`,
};

export const modules_urls = {
  get_all: "/modules/search/",
  get_details: (moduleId) => `/modules/${moduleId}/`,
  update: (moduleId) => `/modules/${moduleId}/`,
  archive: (moduleId) => `/modules/${moduleId}/archive/`,
};

export const questions_urls = {
  get_all: "/questions/search/",
  get_details: (questionId) => `/questions/${questionId}/`,
  update: (questionId) => `/questions/${questionId}/`,
  archive: (questionId) => `/questions/${questionId}/archive/`,
  getQEquivalence: "/list-questions/",
  addQEquivalence: "/questions/create_equivalence/",
  delQEquivalence: (equivalentId) => `/questionsrelation/${equivalentId}/`,
};

export const tags_urls = {
  get_all: "/tags/",
  addTagToQuestion: "/tags/addtoquestion/",
  RemoveTagToQuestion: "/tags/removetoquestion/",
};

export const trainees_urls = {
  get_all: "/trainees/",
  get_details: (traineesId) => `/trainees/${traineesId}/`,
  update: (traineesId) => `/trainees/${traineesId}/`,
};

export const trainingblocs_urls = {
  get_all: "/trainingblocs/search/",
  get_details: (trainingBlocId) => `/trainingblocs/${trainingBlocId}/`,
  update: (trainingBlocId) => `/trainingblocs/${trainingBlocId}/`,
};

export const trainingblocsubscriptions_urls = {
  get_all: "/trainingblocsubscriptions/",
  get_details: (trainingBlocSubscriptionId) =>
    `/trainingblocsubscriptions/${trainingBlocSubscriptionId}/`,
  update: (trainingBlocSubscriptionId) =>
    `/trainingblocsubscriptions/${trainingBlocSubscriptionId}/`,
  post: "/trainingblocsubscriptions/",
};

export const managements_urls = {
  get_all: "/managements/search/",
  get_details: (managementId) => `/managements/${managementId}/`,
  update: (managementId) => `/managements/${managementId}/`,
};

export const comments_urls = {
  get_all: "/comments/",
  get_details: (commentId) => `/comments/${commentId}/`,

  theme_all: "/theme/comments/",
  theme_details: (commentId) => `/theme/comments/${commentId}/`,
  delete_theme: (commentId) => `/theme/comments/${commentId}/`,

  subtheme_all: "/subtheme/comments/",
  subtheme_details: (commentId) => `/subtheme/comments/${commentId}/`,
  delete_subtheme: (commentId) => `/subtheme/comments/${commentId}/`,

  module_all: "/module/comments/",
  module_details: (commentId) => `/module/comments/${commentId}/`,
  delete_module: (commentId) => `/module/comments/${commentId}/`,

  question_all: "/question/comments/",
  question_details: (commentId) => `/question/comments/${commentId}/`,
  delete_question: (commentId) => `/question/comments/${commentId}/`,

  trainee_all: "/trainee/comments/",
  trainee_details: (commentId) => `/trainee/comments/${commentId}/`,
  delete_trainee: (commentId) => `/trainee/comments/${commentId}/`,
};

export const trainingitems_urls = {
  get_all: "/trainingitems/search/",
  get_details: (trainingItemId) => `/trainingitems/${trainingItemId}/`,
};

export const codelz_urls = {
  get_all: "/codelz/search/",
  get_details: (codelzItemId) => `/codelz/${codelzItemId}/`,
  update: (codelzId) => `/codelz/${codelzId}/`,
};

export const pbi_urls = {
  get_token: "/get-pbi-embed-token/",
};

export const trainingcourses_urls = {
  get_all: "/trainingcourses/search/",
  get_details: (trainingCourseId) => `/trainingcourses/${trainingCourseId}/`,
  get_liaison: (themeId, subthemeId) =>
    `/trainingcourses/themesubthemrelation/?theme=${themeId}&subtheme=${subthemeId}`,
  update: (trainingCourseId) => `/trainings/${trainingCourseId}/`,
};

export const testdrills_urls = {
  get_all_fake: "/testdrillfakecourses/",
  get_all: "/testdrills/",
  get_liaison: (themeId, subthemeId) =>
    `/trainingcourses/themesubthemrelation/?theme=${themeId}&subtheme=${subthemeId}`,
  update: (testDrillId) => `/trainings/${testDrillId}/`,
  updateRelation: (testDrillId) => `/testdrills/relation/${testDrillId}/`,
};

export const alert_urls = { get_all: "/alerts/" };
