<template>
  <div class="details question">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails de la Question</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      {{ question[key] }}
                    </div>
                  </div>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-50">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>

                  <div class="checkbox">
                    <v-checkbox
                      v-model="question[key]"
                      @click="checkboxUpdate(key, !question[key])"
                    ></v-checkbox>
                  </div>
                </div>
              </v-list-item>

              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder3"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div :class="{ 'text-primary': key === 'Modules' }">
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      <div v-if="key === 'Modules'">
                        {{ question.Modules }}
                      </div>
                      <div
                          v-else-if="key === 'Tags' && Array.isArray(question.Tags)"
                      >
                        <div class="tags-container">
                          <v-chip
                              v-for="(tag, index) in question.Tags"
                              :key="index"
                              class="ma-2"
                              color="green"
                          >
                            {{ tag.TXT_Tag }}
                            <template #close>
                              <v-icon
                                  small
                                  @click.stop="removeTag(tag)"
                              >
                                mdi-close
                              </v-icon>
                            </template>
                          </v-chip>
                        </div>
                      </div>

                      <div
                          v-else-if="
  key === 'Equivalents' && Array.isArray(question.Equivalents)
"
                      >
                        <v-data-table
                            :headers="equivalentsHeaders"
                            :items="paginatedEquivalents"
                            dense
                            class="elevation-1"
                        >
                          <template #item.TXT_Question="{ item }">
                            <router-link :to="`/questions/${item.ID_EquivalentQuestion.ID_D_Question}`">
                              {{ item.ID_EquivalentQuestion.TXT_Question }}
                            </router-link>
                          </template>
                          <template #item.actions="{ item }">
                            <v-icon
                                icon="mdi-delete-forever"
                                size="large"
                                @click="deleteEquivalent(item)"
                            ></v-icon>
                          </template>
                        </v-data-table>
                        <v-pagination
                            v-model="equivalentsPage"
                            :length="totalEquivalentPages"
                            @input="updatePaginatedEquivalents"
                            class="mt-4"
                        ></v-pagination>
                      </div>

                      <template v-else>
                        {{ question[key] }}
                      </template>
                    </div>
                  </div>

                  <div
                    v-if="key !== 'Modules'"
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsQuestion"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="large"></v-icon>
                      </div>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>
          </v-list>
          <div
            class="d-flex w-100 mt-6 px-10 pb-8 align-center justify-space-between"
          >
            <div class="details-dates d-flex">
              <div
                class="w-100 pa-0 details-dates__element"
                v-for="key in keyOrder4"
                :key="key"
              >
                <div class="element">
                  <div>
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>
                  <div>
                    <!-- Utilisation de la méthode formatDate si la clé est une date -->
                    {{
                      key === "DAT_LastEdited" || key === "DAT_Created"
                        ? formatDate(question[key])
                        : question[key]
                    }}
                  </div>
                </div>
              </div>
            </div>

            <v-btn color="primary" @click="openDeleteQuestionDialog(question)"
              >Supprimer la question</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="fieldToUpdate !== 'modules' && fieldToUpdate !== 'Tags' && fieldToUpdate !== 'Equivalents'"
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <div v-if="fieldToUpdate === 'Equivalents'">
          <v-data-table
              :headers="headers"
              :items="availableQuestions"
              item-value="ID_D_Question"
              dense
              class="elevation-1"
          >
            <template #item.radio="{ item }">
              <v-radio
                  :value="item.ID_D_Question"
                  v-model="selectedEquivalent"
                  @change="onSelectEquivalent(item)"
              ></v-radio>
            </template>
            <template #top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    label="Rechercher"
                    single-line
                    clearable
                    outlined
                    dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template #no-data>
              <v-alert type="info" border="left" elevation="2">
                Aucun résultat trouvé
              </v-alert>
            </template>
          </v-data-table>

        </div>

        <v-container>
          <v-row align="center" justify="center" class="mt-4">
            <v-select
                v-if="fieldToUpdate === 'Tags'"
                v-model="selectedTags"
                :items="Tags.length > 0 ? Tags.map(tag => tag.TXT_Tag) : []"
                label="Sélectionner des Tags"
                multiple
                outlined
                dense
                :menu-props="{ bottom: true }"
            >
              <template #no-data>
                <v-alert type="info" border="left" elevation="2">
                  Aucun tag disponible
                </v-alert>
              </template>
            </v-select>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteQuestionDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer cette question ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteQuestionDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteQuestion">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  questions_urls,
  comments_urls,
  user_urls,
  header, tags_urls,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "QuestionSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      question: {
        comments: [],
        Equivalents: [],
      },
      equivalentsPage: 1,
      equivalentsPerPage: 10,
      paginatedEquivalents: [],
      equivalentsHeaders: [
        { text: "Question", value: "TXT_Question" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      keyTextMap: {
        TXT_Question: "Question",
        TXT_RightAnswer: "Bonne réponse",
        TXT_WrongAnswer1: "Mauvaise répnse 1",
        TXT_WrongAnswer2: "Mauvaise réponse 2",
        TXT_Explication: "Explication",
        TXT_UpdatesToDo: "Modifications à faire",
        TXT_Mission: "Mission Expert",
        TXT_Feedback: "Avis",
        BIT_Active: "Actif ?",
        TXT_Ref: "REF",
        TXT_Notions: "Notions",
        BIT_Validate: "Validé ?",
        TXT_ExpertName: "Expert name ?",
        Modules: "Module associé",
        Tags: "Tags",
        Equivalents: "Questions Equivalent",

        DAT_Created: "Création",
        TXT_LastEditedBy: "Modifié par",
        DAT_LastEdited: "Dernière modification",
      },

      headers: [
        { text: "", value: "radio", width: "10%" },
        { text: "ID Question", value: "ID_D_Question" },
        { text: "Question", value: "TXT_Question" }
      ],
      availableQuestions: [], // Questions available to add as equivalents
      selectedEquivalent: null,
      itemsPerPage: 10,
      currentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totalPages: 0,
      search: "",

      selectedTags: [],
      keyOrder1: [
        "TXT_Question",
        "TXT_RightAnswer",
        "TXT_WrongAnswer1",
        "TXT_WrongAnswer2",
        "TXT_Explication",
        "TXT_UpdatesToDo",
        "TXT_Mission",
        "TXT_Feedback",
      ],
      keyOrder2: ["BIT_Active", "BIT_Validate"],
      keyOrder3: ["TXT_ExpertName", "TXT_Ref", "TXT_Notions", "Modules", "Tags", "Equivalents"],
      keyOrder4: ["DAT_Created", "DAT_LastEdited", "TXT_LastEditedBy"],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,
      questionToDelete: null,
      deleteQuestionDialog: false,

      Modules: [],
      Tags: [],

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },
  watch: {
    search: "fetchAvailableQuestions",
    equivalentsPage: "updatePaginatedEquivalents",
  },
  methods: {
    onSelectEquivalent(item) {
      this.selectedEquivalent = item.ID_D_Question;
    },
    updatePaginatedEquivalents() {
      if (!Array.isArray(this.question.Equivalents)) {
        this.paginatedEquivalents = [];
        return;
      }
      const start = (this.equivalentsPage - 1) * this.equivalentsPerPage;
      const end = start + this.equivalentsPerPage;
      this.paginatedEquivalents = this.question.Equivalents.slice(start, end);
    },

    openDeleteQuestionDialog(question) {
      this.questionToDelete = question;
      this.deleteQuestionDialog = true;
    },
    fetchAvailableQuestions(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }
      const { itemsPerPage, page } = this.options;
      this.loading = true;
      let url = `${base_url}${questions_urls.get_all}?page=${page}&page_size=${itemsPerPage}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      axios
          .get(url,  header )
          .then((response) => {
            const resultsArray = response.data.results.results;
            if (Array.isArray(resultsArray)) {
              this.availableQuestions = resultsArray;
              this.totalPages = Math.ceil(response.data.count / this.itemsPerPage);
              this.currentPage = page;
            } else {
              console.error("Expected an array but got:", resultsArray);
            }
          })
          .catch((error) => {
            console.error("Error fetching available questions:", error);
          });
    },

    addEquivalent() {
      if (!this.selectedEquivalent) {
        console.error("Error: No equivalent selected.");
        alert("Veuillez sélectionner une question équivalente avant de continuer.");
        return;
      }

      const url = `${base_url}${questions_urls.addQEquivalence}`;
      const payload = {
        ID_D_Question: this.question.ID_D_Question,
        ID_EquivalentQuestion: this.selectedEquivalent,
      };

      axios
          .post(url, payload, header)
          .then((response) => {
            this.question.Equivalents.push(response.data); // Update local list
            this.snackbarMessage =
                "L'équivalence a été ajoutée avec succès.";
            this.snackbar = true;
            location.reload();
          })
          .catch((error) => {
            console.error("Error adding equivalent:", error);
            this.snackbarMessage =
                "Une erreur s'est produite perdant de l'ajout.";
            this.snackbarColor = "error";
            this.snackbar = true;          });
    },
    deleteEquivalent(equivalent){
      const equivalentId = equivalent.ID_BT_EquivalentQuestion;
      const url = `${base_url}${questions_urls.delQEquivalence(equivalentId)}`;
      if (confirm("Êtes-vous sûr ?")){
        axios.delete(url, header).then(() => {
          this.question.Equivalents.filter(
              (eq) => eq.ID_D_Question !== equivalentId
          );
          this.snackbarMessage ="l'equivalence a été supprimé avec succès.";
          this.snackbar = true;
          location.reload();
        })
            .catch((error) => {
              console.error("Erreur suppression équivalence:", error);
              this.snackbarMessage =
                  "Une erreur s'est produite pendant de la suppression.";
              this.snackbarColor = "error";
              this.snackbar = true;
            })
      }
    },

    fetchTags() {
      const url = `${base_url}${tags_urls.get_all}`;
      axios
          .get(url, header)
          .then((response) => {
            this.Tags = response.data.results || response.data;
          })
          .catch((error) => {
            console.error("Error fetching tags:", error);

          });
    },

    addTagsToQuestion() {
      if (!this.selectedTags || this.selectedTags.length === 0) {
        console.error("No tags selected.");
        alert("Veuillez sélectionner un ou plusieurs tags avant de continuer.");
        return;
      }

      const url = `${base_url}${tags_urls.addTagToQuestion}`;
      const promises = this.selectedTags.map((tagName) => {
        const tag = this.Tags.find((t) => t.TXT_Tag === tagName);
        if (tag) {
          const payload = {
            ID_D_Question: this.question.ID_D_Question,
            ID_D_Tag: tag.ID_D_Tag,
          };
          return axios.post(url, payload, header);
        } else {
          console.error(`Tag not found: ${tagName}`);

          return Promise.reject(`Tag not found: ${tagName}`);
        }
      });
      // Looping pour créer plusieurs relation tags => Question
      Promise.all(promises)
          .then(() => {
            this.selectedTags.forEach((tagName) => {
              const tag = this.Tags.find((t) => t.TXT_Tag === tagName);
              if (tag) {
                this.question.Tags.push(tag);
              }
            });
            this.selectedTags = [];
            this.snackbarMessage = "Confirmation de l'ajout de la relation au tag.";
            this.snackbarColor = "success";
            this.snackbar = true;
            location.reload();
          })
          .catch((error) => {
            console.error("Error adding tags:", error);
            this.snackbarMessage =
                "Une erreur s'est produite lors de l'ajout des tags.";
            this.snackbarColor = "error";
            this.snackbar = true;
          });
    },

    removeTag(tag) {

      const payload = {
        ID_D_Question: this.question.ID_D_Question,
        ID_D_Tag: tag.ID_D_Tag,
      }
      const url = `${base_url}${tags_urls.RemoveTagToQuestion}`;
      axios
          .post(url, payload, header)
          .then(() => {
            this.snackbarMessage = `Confirmation de la suppressions de la relation du tag "${tag.TXT_Tag} ".`;
            this.snackbarColor = "success";
            this.snackbar = true;
            location.reload();
          })
          .catch((error) => {
            console.error("Error removing tag:", error);
            this.snackbarMessage =
                "Une erreur s'est produite lors de la suppression du tag.";
            this.snackbarColor = "error";
            this.snackbar = true;
          })
    },


    async fetchUserDetails() {
      const userId = localStorage.getItem("id");
      if (userId) {
        try {
          const response = await this.getUserDetails(userId);
          this.userDetails = response.data;
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de l'utilisateur:",
            error
          );
          this.snackbarMessage =
            "Erreur lors de la vérification des droits utilisateur.";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      } else {
        this.snackbarMessage =
          "Impossible de vérifier les droits utilisateur. Veuillez vous reconnecter.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    async deleteQuestion() {
      const questionId = this.$route.params.id;
      const url = `${base_url}${questions_urls.archive(questionId)}`;

      await this.fetchUserDetails();

      if (!this.userDetails?.is_superuser) {
        this.snackbarMessage =
          "Vous n'avez pas les droits nécessaires pour supprimer cette question.";
        this.snackbarColor = "error";
        this.snackbar = true;
        return;
      }

      if (this.questionToDelete) {
        const payload = {
          is_admin: true,
        };

        try {
          await axios.post(url, payload, header);
          this.snackbarMessage = `La Question a été supprimée.`;
          this.snackbar = true;
          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);
          this.$router.push("/questions");
        } catch (error) {
          console.error("Erreur lors de la suppression de la question:", error);
          this.snackbarMessage = `Erreur lors de la suppression de la <strong>Question</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        }
        this.deleteQuestionDialog = false;
      }
    },

    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getQuestionDetails() {
      const questionId = this.$route.params.id;
      const url = `${base_url}${questions_urls.get_details(questionId)}`;
      const commentsUrl = `${base_url}${comments_urls.question_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.question = {
            ...response.data,
          };
          if (!Array.isArray(this.question.Equivalents)) {
            this.question.Equivalents = [];
          }
          if (this.question.TXT_LastEditedBy) {
            return this.getUserDetails(this.question.TXT_LastEditedBy);
          } else {
            return Promise.resolve(null);
          }
        })
        .then((userResponse) => {
          // Replace the user ID with username
          if (userResponse) {
            this.question.TXT_LastEditedBy = userResponse.data.username;
          }
          return axios.get(commentsUrl, header);
          // Fetch comments
        })
        .then((commentsResponse) => {
          this.question.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_Question === parseInt(questionId)
          );
          const uniqueUserIds = [
            ...new Set(
              this.question.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.question.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
          this.updatePaginatedEquivalents();
        })
        .catch((error) => {
          console.error("Error fetching question details:", error);
        });
    },

    openAddCommentDialog() {
      this.newComment = ""; // Reset the new comment field
      this.addCommentDialog = true;
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Ajouter une ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "modules") {
        this.newFieldValue = this.question[key].map(
          (module) => module.ID_D_Module__TXT_Module
        );
      } else if (key === "Tags") {
        this.newFieldValue = this.question[key].map((Tags) => Tags.TXT_Tag);
      } else {
        this.newFieldValue = this.question[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (this.newFieldValue) {
        if (this.fieldToUpdate === "modules") {
          const newModules = this.Modules.filter((module) =>
            this.newFieldValue.includes(module.ID_D_Module__TXT_Module)
          );
          this.updateQuestion(this.fieldToUpdate, newModules);
        } else if (this.fieldToUpdate === "TXT_Comment") {
          this.updateComment(this.currentCommentID, this.newFieldValue);
        } else if (this.fieldToUpdate === "Equivalents") {
          if (!this.selectedEquivalent) {
            console.error("Error: No equivalent selected.");
            alert("Veuillez sélectionner une question équivalente avant de continuer.");
            return;
          }
          this.addEquivalent()
        }
        else {
          this.updateQuestion(this.fieldToUpdate, this.newFieldValue);
        }
        this.dialog = false;
      }
    },
    checkboxUpdate(key, newValue) {
      this.updateQuestion(key, newValue);
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.question_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.question.comments.findIndex(
            (comment) => comment.ID_FS_CommentsQuestion === commentId
          );
          this.question.comments[commentIndex].TXT_Comment = newComment;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    updateQuestion(field, newValue) {
      // Update the question on the server
      const questionId = this.$route.params.id;
      const url = `${base_url}${questions_urls.update(questionId)}`;

      const updatedData = {
        TXT_Question: this.question.TXT_Question,
        TXT_RightAnswer: this.question.TXT_RightAnswer,
        TXT_WrongAnswer1: this.question.TXT_WrongAnswer1,
        TXT_WrongAnswer2: this.question.TXT_WrongAnswer2,
        TXT_Explication: this.question.TXT_Explication,
        TXT_Mission: this.question.TXT_Mission,
        TXT_Feedback: this.question.TXT_Feedback,
        TXT_UpdatesToDo: this.question.TXT_UpdatesToDo,
        BIT_Active: this.question.BIT_Active,
        TXT_Ref: this.question.TXT_Ref,
        TXT_Notions: this.question.TXT_Notions,
        TXT_ExpertName: this.question.TXT_ExpertName,

        DAT_Created: this.question.DAT_Created,
        [field]: newValue, // Override the field to update with the new value
        DAT_LastEdited: new Date().toISOString(), // Current date
        TXT_CreatedBy: this.question.TXT_CreatedBy, // Created by field should not change
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store

        modules: this.question.modules,
        Tags: this.question.Tags,
      };

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.question = response.data;

          this.getQuestionDetails();

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsQuestion; // Stocker l'ID du commentaire actuel
    },

    addComment() {
      const url = `${base_url}${comments_urls.question_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_Question: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getQuestionDetails(); // Fetch subtheme details again to update the comments list

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbar = true;

          this.addCommentDialog = false; // Close the dialog
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },
    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_question(
          this.commentToDelete.ID_FS_CommentsQuestion
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getQuestionDetails();

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
  },

  computed: {
    reversedComments() {
      return [...(this.question.comments || [])].reverse();
    },
    totalEquivalentPages() {
      return Math.ceil(this.question.Equivalents.length / this.equivalentsPerPage);
    },
  },

  created() {
    this.getQuestionDetails();
  },

  mounted() {
    document.title = "Question - Détails";
    this.fetchAvailableQuestions();
    this.fetchTags()
    this.updatePaginatedEquivalents();

  },
};
</script>

<style lang="scss">
.details.question .data-table .v-table .v-data-table__td {
  width: 100%;
}

.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
.scrollable-list {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ddd; /* Optional for better visibility */
  padding: 8px;
}
.scrollable-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.scrollable-list li {
  margin-bottom: 8px;
}


</style>
